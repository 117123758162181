// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
import env from './.env';

export const environment = {
  production: false,
  version: env.npm_package_version + '-dev',
  serverUrl: '/api',
  envName: 'DEV',
  // apiUrl:'/api',
  apiUrl: 'https://demo.tasksplan.com:7777/api',
  //  apiUrl: 'https://mfsplaykit.tasksplan.com/api',
  // apiUrl: 'http://3.14.175.22:7777/api',
  // IMAGE_URL: 'https://mfsplaykit.tasksplan.com/',
  // IMAGE_URL_IP: 'http://3.21.252.17:7000/',
  // apiUrl: 'http://3.21.252.17:7000/api',
  // apiUrl: 'http://192.168.1.85:7000/api',
  imageUrl:'https://demo.tasksplan.com:7777/'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
