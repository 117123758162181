import { Injectable } from '@angular/core';
import { 
    ActivatedRouteSnapshot, 
    CanActivate, 
    Router, 
    RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { TrainingTaskService } from '@app/core/service/training-task.service';
import { GlobalService } from '@app/core/service/global.service';

@Injectable()
export class AssessmentGuard implements CanActivate {
    constructor(public router: Router, private service: TrainingTaskService, private location: Location) { }
    canActivate() {
        // console.log(next, state);
        const tPermissions = GlobalService.getPermission('Assess It');
        if (tPermissions['begin assessment']) {
            return this.service
                .getCurrentTrainingStatus()
                .then((e: boolean) => {
                    if (e) {
                        return true;
                    } else {
                        this.location.back();
                        return false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.location.back();
                    return false;
                });
        } else {
            this.location.back();
            return false;
        }
    }
}