<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" [routerLink]="['/dashboard']" (click)="emitIndex(false)" >
    <!-- <img *ngIf="!isIndexPage" height="40" src="../../../assets/images/logoBlackW.png">
    <img *ngIf="isIndexPage" height="50" src="../../../assets/images/logoBlackW.png"> -->
    <img style="height: 60px;" [src]="logImage">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" 
    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
 
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto" *ngIf="!isIndexPage">
      <li *ngFor="let item of navItems" class="nav-item" routerLinkActive="active" >
        <a class="nav-link desktop-view" [routerLink]="item.link"  >{{item.title}}</a>
        <a class="nav-link  mobile-view" [routerLink]="item.link"  data-toggle="collapse" 
        data-target="#navbarSupportedContent" >{{item.title}}</a>
      </li>
      <li><select [ngClass]="isIndexPage ? 'selectpicker' : 'selectpicker1'"  (change)="selectLang($event)" >
        <option *ngFor="let lang of langItems;" [value]="lang" >{{lang}}</option>
      </select></li>
    </ul>
    <ul class="navbar-nav user-menu">
      <li class="nav-item"><a class="nav-link">{{name}}</a></li>
      <li class="nav-item"><a class="nav-link">|</a></li>
      <li class="nav-item"><a class="nav-link" style="cursor: pointer;"  (click)="logout()" >Logout</a></li>
    </ul>
  </div>
  
</nav>